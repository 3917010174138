import { authHeader } from "../_helpers";

export const urgentsService = {
  getAll,
  get,
  check,
  getLabels,
  getFields,
  post,
  put,
  del,
};

function check(email, birthdate, postcode, housenumber) {
  return authHeader().then(function (token) {
    const requestOptions = {
      method: "POST",
      headers: token,
      body: JSON.stringify({ email, birthdate, postcode, housenumber }),
    };

    return fetch(
      `https://${process.env.VUE_APP_API_URL}/check`,
      requestOptions
    ).then(function (response) {
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        return data;
      });
    });
  });
}

function get(id) {
  return authHeader().then(function (token) {
    const requestOptions = {
      method: "GET",
      headers: token,
    };

    return fetch(
      `https://${process.env.VUE_APP_API_URL}/urgents/${id}`,
      requestOptions
    ).then(function (response) {
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
        return data;
      });
    });
  });
}

function getAll(
  offset,
  limit,
  label,
  regdate,
  status,
  keyword,
  lastname,
  rooms,
  ageFrom,
  ageTo
) {
  return authHeader().then(function (token) {
    const requestOptions = {
      method: "GET",
      headers: token,
    };
    let url = new URL(`https://${process.env.VUE_APP_API_URL}/urgents`);
    url.search = new URLSearchParams({
      offset,
      limit,
      label,
      regdate,
      status,
      keyword,
      lastname,
      rooms,
      ageFrom,
      ageTo,
    });

    return fetch(url, requestOptions).then(function (response) {
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        return data;
      });
    });
  });
}

function getLabels() {
  return authHeader().then(function (token) {
    const requestOptions = {
      method: "GET",
      headers: token,
    };

    return fetch(
      `https://${process.env.VUE_APP_API_URL}/settings/labels`,
      requestOptions
    ).then(function (response) {
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
        return data;
      });
    });
  });
}

function getFields() {
  return authHeader().then(function (token) {
    const requestOptions = {
      method: "GET",
      headers: token,
    };

    return fetch(
      `https://${process.env.VUE_APP_API_URL}/settings/profilefields`,
      requestOptions
    ).then(function (response) {
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
        return data;
      });
    });
  });
}

function post(naw, profile, accountnumber) {
  return authHeader().then(function (token) {
    const requestOptions = {
      method: "POST",
      headers: token,
      body: JSON.stringify({ naw, profile, accountnumber }),
    };

    return fetch(
      `https://${process.env.VUE_APP_API_URL}/urgents`,
      requestOptions
    ).then(function (response) {
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        return data;
      });
    });
  });
}

function put(naw, profile, id) {
  return authHeader().then(function (token) {
    const requestOptions = {
      method: "PUT",
      headers: token,
      body: JSON.stringify({ naw, profile, id }),
    };

    return fetch(
      `https://${process.env.VUE_APP_API_URL}/urgents`,
      requestOptions
    ).then(function (response) {
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        return data;
      });
    });
  });
}

function del(id) {
  return authHeader().then(function (token) {
    const requestOptions = {
      method: "DELETE",
      headers: token,
    };

    return fetch(
      `https://${process.env.VUE_APP_API_URL}/urgents/${id}`,
      requestOptions
    ).then(function (response) {
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
        return data;
      });
    });
  });
}
